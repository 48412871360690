<template>
  <div class="guide-card-editor">
    <div class="guide-card-editor__wrapper">
      <Column>
        <Row class="guide-card-editor__avatar-holder"
             justify="between">
          <UserAvatar :params="{avatar: params.images, size: 50}"/>
          <Icon v-if="params.status !== 'moderated'"
                class="guide-card-editor__settings-caller"
                v-on:click.native="$openPopupAddGuide(params)"
                xlink="settings"
                viewport="0 0 20 20"/>
        </Row>
        <Column class="guide-card-editor__name-annotation">
          <span class="guide-card-editor__name">{{params.name}}</span>
          <span class="guide-card-editor__annotation">{{description}}</span>
        </Column>
        <Row justify="between"
             full>
          <Column v-if="params.phone"
                  class="guide-card-editor__title-value">
            <span class="guide-card-editor__title">Телефон</span>
            <span class="guide-card-editor__value">{{params.phone}}</span>
          </Column>
          <Column class="guide-card-editor__title-value">
            <span class="guide-card-editor__title">Город</span>
            <span class="guide-card-editor__value">{{city}}</span>
          </Column>
        </Row>
        <Row class="guide-card-editor__button-holder"
             full>
          <Button v-if="params && params.status === 'active'"
                  color="like-link"
                  v-on:click.native="$openPopupAddGuide(params)"
                  full>Смотреть профиль
          </Button>
          <Button v-if="params && params.status === 'new'"
                  color="green"
                  v-on:click.native="$openPopupAddGuide(params)"
                  full>Стать гидом
          </Button>
          <Button v-if="params && params.status === 'moderated'"
                  color="green"
                  disabled
                  full>На модерации
          </Button>
          <Button v-if="params && params.status === 'nonactive'"
                  color="red"
                  v-on:click.native="$openPopupAddGuide(params)"
                  full>Заявка отклонена
          </Button>
        </Row>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuideCardEditor',
  props: {
    params: {},
  },
  computed: {
    description() {
      let string = this.params.description;
      if (string.length > 80) {
        string = string.slice(0, 80);
        string = `${string}...`;
      }
      return string;
    },
    city() {
      return this.$cities.data.find(item => item.id === this.params.city_id).name;
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/style/utilits/index";

  .guide-card-editor {
    &__wrapper {
      @include position(relative);
      @include size(350px, 355px);
      background-color: $white;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18),
      0 1px 2px 0 rgba(0, 0, 0, 0.04),
      0 2px 6px 0 rgba(0, 0, 0, 0.04);
    }

    &__avatar-holder {
      margin: 0 0 15px;
    }

    &__settings {

      &-caller {
        @include position(relative);
        z-index: 2;
        fill: $border;
        @extend .extend-button;
      }
    }

    &__name-annotation {
      margin: 0 0 15px;
    }

    &__name {
      margin: 0 0 2px;
      @include font(16px, bold, 1.5);
    }

    &__annotation {
      @include size(null, 42px);
      @include font(14px, normal, 1.57);
      color: rgba(0, 0, 0, .58);
      overflow: hidden;
    }

    &__title-value {
      @include size(50%);
    }

    &__title {
      @include font(14px, bold, 30px);
    }

    &__value {
      @include font(14px, normal, 30px);
      color: rgba(0, 0, 0, .58);
      @extend .extend-text-overflow-hidden;
    }

    &__button-holder {
      @include position(absolute, $left: 0, $bottom: 20px);
      padding: 0 20px;
    }
  }
</style>
